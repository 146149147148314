import logo from './logo.svg';
import './App.css';
import Home from './pages/home/home';
import React from 'react';
import NavBar from './universal-components/navigation-components/side-nav-bar';
import Footer from './universal-components/footer/footer';
function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
/*
import logo from './logo.svg';
import './App.css';
import Home from './home/home';
import React from 'react';
import NavBar from './universal-components/navigation-components/side-nav-bar';
import { connect } from "react-redux"
import {
  increaseCounter,
  decreaseCounter,
} from "./redux/counter/counter.action"
function App(props) {
  return (
    <>
      <div>

      <div>Count: {props.count}</div>

      <button onClick={() => props.increaseCounter()}>Increase Count</button>

      <button onClick={() => props.decreaseCounter()}>Decrease Count</button>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  return {
    count: state.counter.count,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    increaseCounter: () => dispatch(increaseCounter()),
    decreaseCounter: () => dispatch(decreaseCounter()),
  }
}
/*
<NavBar />
      <Home />
export default connect(mapStateToProps, mapDispatchToProps)(App)
*/